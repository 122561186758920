<template>
  <IconCard
    :ui="{
      header: {
        padding: 'pb-0'
      },
      body: {
        padding: 'sm:pb-5'
      }
    }"
    :icon="icon"
    :color="color"
    use-ring
  >
    <template #header>
      <h6 class="text-sm mb-0 mt-1 sm:mt-1.5 lg:mt-0.5">
       {{ title }}
      </h6>
    </template>
    <ClientOnly>
      <div 
        class="flex flex-wrap"
      > 
        <USkeleton 
          v-for="i in loading && !instellingenListCard.length ? baseListLength : 0"
          class="rounded-full ring-1 ring-white dark:ring-gray-800 h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 lg:w-14 lg:h-14 -ml-1 sm:-ml-2"
        />
        
        <UPopover 
          v-for="instelling in instellingenListCard"
          mode="hover" 
          :open-delay="300" 
          :disabled="isMounted && !grid.sm"
          :ui="{
            ring: 'dark:ring-gray-700'
          }"
          class="first:ml-0 -ml-1 sm:-ml-2 hover:z-10"
        >
          <NuxtLink :to="profileRoute(instelling)" tabindex="-1">
            <UAvatar 
              :src="instelling.imageUrl"
              :alt="instelling.name"
              :ui="{
                size: {
                  xl: 'text-sm'
                },
                text: 'font-bold',
                placeholder: 'font-bold',
              }"
              :size="grid.lg ? 'xl' : grid.md ? 'lg' : grid.sm ? 'md' : 'sm'"
              style="font-size: 12px;"
              class="ring-2 ring-gray-100 dark:ring-gray-700 hover:ring-primary-500 dark:hover-ring-primary-500 text-sm "
            />
          </NuxtLink>
        

          <template #panel>
            <OpleiderPreview 
              :data="instelling"
            />
          </template>
        </UPopover>
        <div
          v-if="extraRecords > 0 && showExtraRecords"
          class="relative bg-gray-100 dark:bg-gray-700 rounded-full h-8 w-8 sm:w-10 sm:h-10 md:w-12 md:h-12 lg:w-14 lg:h-14 grid place-items-center font-bold text-sm ring-2 ring-gray-100 dark:ring-gray-700 -ml-1 sm:-ml-2"
        >
        +{{ extraRecords }}
        </div>
        
      </div>
      <template #fallback>
        <div class="flex flex-wrap">
          <USkeleton 
              v-for=" i in 9"
              class="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 lg:w-14 lg:h-14 -ml-1 sm:-ml-2 ring-1 rounded-full ring-white dark:ring-gray-800"
            />
        </div>
        
      </template>
    </ClientOnly>
    <template v-if="extraRecords > 0 || loading" #footer>
      <NavigatorDetailsList 
        v-model="showAll"
        :title="title"
        :icon="icon"
        :color="color"
        :show-more-label="showAllBtnLabel"
      >
        <OpleiderTable 
          :data="instellingenList" 
          :loading="loading" 
          :error="error"
          expanded
        />   
      </NavigatorDetailsList>
    </template>
    
  </IconCard>
</template>

<script lang="ts" setup>

  import type { AlgoliaOnderwijsInstelling, AlgoliaRelatedOnderwijsinstelling } from '~~/types/algolia';
  const props = withDefaults(defineProps<{
    data?: Array<AlgoliaOnderwijsInstelling | AlgoliaRelatedOnderwijsinstelling> | null,
    loading: boolean,
    error: any,
    isGeoSearch?: boolean
    title?: string
    color?: BaseColor
    showExtraRecords?: boolean
  }>(), {
    data: () => [],
    color: 'primary',
    showExtraRecords: true,
    fetchAdditionalData: false
  })


  const instellingenCount = computed(() => props.data?.length || 0)

  const { grid } = useResponsive()

  const instellingenList = computed(() => {
    if (!props.data) return []
    return props.data.map((rec: any) => {
      return {
        ...rec,
        distance: rec._rankingInfo?.geoDistance
      }
    })
  })

  const { isMounted } = useUiState()


  const baseListLength = computed(() => grid.lg ? 9 : grid.md ? 7 : grid.sm ? 12 : 9)


  const instellingenListCard =  computed(() => instellingenList.value?.slice(0, baseListLength.value))

  // Variables to determine if the "show all" panel should be used
  const extraRecords = computed(() => instellingenCount.value - baseListLength.value)
  const showAll = ref(false)

  const title = computed(() => props.loading ? 'Aan het zoeken naar opleiders...' : props.title ? props.title : `${instellingenCount.value} opleiders gevonden ${props.isGeoSearch ? ' binnen jouw zoekgebied' : ''}`)
  const { icons } = useConstants()
  const icon = icons['opleider']
  const showAllBtnLabel = computed(() => `${ showAll.value ? 'verberg' : 'toon' } alle opleiders`)


  const profileRoute = (item: AlgoliaOnderwijsInstelling | AlgoliaRelatedOnderwijsinstelling) => {
    if ('slug' in item) return '/onderwijsinstellingen/' + item.slug
    return '/onderwijsinstellingen/'
  }

</script>

<style>

</style>