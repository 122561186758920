<template>
  <SearchResultsTable
    type="onderwijsinstellingen"
    :columns="columns"
    :use-header="false"
    :loading="loading"
    :data="data"
    :error="error"
  >
  <template #name-data="{ row }">
      <UPopover 
        mode="hover" 
        :open-delay="500" 
        :disabled="isMounted && !grid.sm"
      >
        <RowHead
          :name="row.name"
          :image="row.imageUrl"
          :to="'/onderwijsinstellingen/' + row.slug"
          :expanded="expanded"
          :icon="icons.onderwijsinstelling"
          class="mt-1"
        />

        <template #panel>
          <OpleiderPreview 
            :data="row"
          />
        </template>
      </UPopover>
    </template>
    <template #plaats-data="{row}">
      <Plaats :label="row.plaats" :distance="row._rankingInfo?.geoDistance" />
    </template>

    <template #onderwijsvormen-data="{row}">
      <Onderwijsvormen :data="row.onderwijsvormen" />
    </template>
    <template #opleidingen-data="{row}">
      <OpleidingenCount :count="row.opleidingen.length" />
    </template>
  </SearchResultsTable>
</template>

<script lang="ts" setup>
import type { AlgoliaOnderwijsInstelling } from '~~/types/algolia';
import type { SearchResponse } from 'algoliasearch/lite';

const props = defineProps<{
  loading?: boolean
  data: SearchResponse<AlgoliaOnderwijsInstelling>[]
  error: any
  expanded?: boolean
}>()

const { icons } = useConstants()


const { grid } = useResponsive()

const { isMounted} = useUiState()

const columns = computed(() => {
  const list = [
    {
      key: 'name',
    },
    {
      key: 'plaats',
    },
    {
      key: 'onderwijsvormen'
    },
    {
      key: 'opleidingen'
    },
  ]

  return list
})

</script>

<style>

</style>